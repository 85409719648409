.gradient-text {
  background-image: linear-gradient(90deg, #7667dd, #b168c9, #e16887, #e47b3e);
  background-clip: text;
  color: transparent;
  font-weight: 800;
  font-size: 32px;
  /* opacity: 1; */
}

.gray-text {
  font-size: 30px;
  /* color: #343838; */
  color: rgb(102, 102, 102);

  /* color: rgb(107 114 128); */
  /* opacity: 0.8; */
}
.marginBottom {
  margin-bottom: 20px;
}

.title {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
