.animate__animated.animate__swing {
  --animate-duration: 20s;
}
.animate__animated.animate__zoomIn {
  --animate-duration: 20s;
}

.appButton {
  box-shadow: 0 10px 25px rgb(123 104 238 / 50%);
  cursor: pointer;
}

.landing-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4rem;
  padding-top: 1rem;
}
.home-image {
  object-fit: contain;
  width: 100%;
  min-width: 20dvw;
}
.right-column {
  position: relative;
}
@media (prefers-reduced-motion: no-preference) {
  .slide-up {
    animation: slide-up 0.5s ease forwards; /* Define animation */
    animation-delay: 0s; /* Delay animation start */
  }

  @keyframes slide-up {
    0% {
      transform: translateY(400px);
      opacity: 0;
    }
    70% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .slide-down {
    animation: slide-down 0.5s ease forwards; /* Define animation */
    animation-delay: 0s; /* Delay animation start */
  }

  @keyframes slide-down {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    70% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.subTitley {
  color: #333333;
  text-align: left;
  font-size: 20px;
  margin-top: 1rem;
}
.titley {
  font-family: GreycliffCF-ExtraBold, sans-serif;
  color: #2b2e32;
  text-align: left;
  font-size: var(--fs-800-clamp);
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes background-pan {
    from {
      background-position: 0% center;
    }

    to {
      background-position: -200% center;
    }
  }
}

.titley-gradient {
  background-image: linear-gradient(
    90deg,
    #097fa6,
    #00a0d1,
    #4c9fcf,
    #00a0d1,
    #097fa6
  );
  animation: background-pan 5s linear infinite;
  background-size: 200%;
  background-clip: text;
  color: transparent;
  font-family: GreycliffCF-ExtraBold, sans-serif;
  white-space: nowrap;
  font-size: var(--fs-800-clamp);
}
@media (max-width: 40em) {
  .landing-container {
    padding-top: 0;
    padding-bottom: 1rem;
    flex-direction: column;
  }
  .right-column {
    margin-block: 2rem;
  }
  .subTitley {
    margin-top: 0;
  }
}
.button {
  border: none;
  border-radius: 10px;
  padding-inline: 2rem;
  padding-block: 0.75rem;
  height: fit-content;
  align-self: center;
  background-color: white;
  min-width: max-content;
  color: black !important;
}
.button:hover {
  cursor: pointer;
  background-color: lightgray;
}
.homepage {
  margin: 0;
  align-items: center;
  min-height: 100dvh;
  padding-top: 6rem;
}
.landing-download-button {
  margin-top: 1rem;
  background-color: #2b2e32;
  border-width: 0;
  width: max-content;
  display: flex;
  align-items: center;
  overflow-x: clip;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  color: white;
  border-radius: 30px;
}
.arrow-right {
  transition: all 0.3s ease;
  animation-name: reverse;
  animation-duration: 0.3s;
}
.landing-download-button:hover .arrow-right {
  animation: zip 0.6s forwards;
}
@keyframes zip {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(200px);
  }
}
@keyframes reverse {
  0% {
    transform: translateX(200px);
  }
  70% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
.landing-download-button,
.header-download-button,
.header_icon,
.feature-brief-download-button {
  transition: transform 0.3s ease;
}
.landing-download-button:hover,
.header-download-button:hover,
.header_icon:hover,
.feature-brief-download-button:hover {
  transform: scale(1.05);
}
.landing-download-button:hover,
.feature-brief-download-button:hover {
  background-image: linear-gradient(-75deg, #3edbda, #09a4d6, #048ad6, #007dd2);
}
.header-download-button:hover {
  background-image: linear-gradient(-75deg, #007dd2, #048ad6, #09a4d6, #3edbda);
}
