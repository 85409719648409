@import url("../App.css");
.contact-us {
  display: flex;
  justify-content: space-between;
}
.footer {
  background-color: black;
  padding-block: 1.25rem;
  color: white;
}
.logo-links {
  display: flex;
  justify-content: space-between;
  width: 8rem;
  padding-top: 2rem;
}
.logo:hover {
  cursor: pointer;
}
.email {
  color: white;
  text-decoration: none;
}
.email:hover {
  color: var(--clr-primary-400);
}
@media (max-width: 50em) {
  footer {
    padding-block: 2rem;
  }
  .contact-us {
    flex-direction: column;
  }
  .contact-us .button {
    width: 100%;
    margin-top: 2rem;
  }
  .footer-resources {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .footer-resources p {
    color: #999999;
  }
}
