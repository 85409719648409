@import url("../App.css");
.about-us {
  padding-block: 2rem;
}
.about-us-body {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding-inline: 2rem;
  gap: 3rem;
}
@media (prefers-reduced-motion: no-preference) and (min-width: 60rem) {
  .show-about-us {
    animation: close-in-about 0.5s ease forwards;
  }
  @keyframes close-in-about {
    0% {
      gap: 20rem;
      opacity: 0;
    }
    70% {
      gap: 2.75rem;
    }
    100% {
      gap: 3rem;
      opacity: 1;
    }
  }
}
.about-us-text {
  color: var(--clr-neutral-100);
  width: 35%;
}
@media (max-width: 60em) {
  .about-us {
    padding-block: 1rem;
  }
  .about-us-body {
    flex-direction: column;
    padding-inline: 1rem;
  }
  .about-us-text {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
  }
}
