.centeredDiv {
  /* margin: auto; */
  display: flex;
  justify-content: center;
}

input {
  /* padding-left: 8px; */
  height: 30px;
  width: 70%;
  max-width: 500px;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  border-radius: 3px;
  font-size: 12px;
}
