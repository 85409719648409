/* Custom Properties */
:root {
  --clr-accent-400: #cbf3ff;
  --clr-accent-100: #4cc5e8;

  --clr-primary-400: #00a0d2;

  --clr-neutral-100: #4c4c4c;
  --clr-neutral-900: hsl(0 0% 0%);

  --ff-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --fs-100: 0.8125rem;
  --fs-200: 0.875rem;
  --fs-300: 0.9375rem;
  --fs-400: 1rem;
  --fs-500: 1.875rem;
  --fs-600: 2.5rem;
  --fs-700: 3.5rem;
  --fs-800: 4.5rem;
  --fs-900: 5.5rem;
  --side-padding: 8rem;

  --fs-800-clamp: clamp(2rem, calc(1.5rem + 4vw), 4.5rem);

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);
}

@media (min-width: 50em) {
  :root {
    --fs-body: var(--fs-500);
    --fs-primary-heading: var(--fs-900);
    --fs-secondary-heading: var(--fs-800);
    --fs-nav: var(--fs-300);
  }
}
@media (max-width: 60em) {
  :root {
    --fs-100: 0.75rem;
    --fs-200: 0.8125rem;
    --fs-300: 0.875rem;
    --fs-400: 0.9375rem;
    --fs-500: 1.5rem;
    --fs-600: 1.875rem;
    --fs-700: 2.5rem;
    --fs-800: 3.5rem;
    --fs-900: 4.5rem;
    --side-padding: 1.5rem;
  }
}
/* ACCESSIBILITY */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1s !important;
    animation-duration: 1s !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
  }
}

/* Custom Properties - BOTTOM */

/* Utility classes - TOP */

.text-primary-400 {
  color: var(--clr-primary-400);
}
.text-accent-400 {
  color: var(--clr-accent-400);
}
.text-accent-100 {
  color: var(--clr-accent-100);
}
.text-neutral-100 {
  color: var(--clr-neutral-100);
}
.text-neutral-900 {
  color: var(--clr-neutral-900);
}

.bg-primary-400 {
  background-color: var(--clr-primary-400);
}
.bg-accent-400 {
  background-color: var(--clr-accent-400);
}
.bg-accent-100 {
  background-color: var(--clr-accent-100);
}
.bg-neutral-100 {
  background-color: var(--clr-neutral-100);
}
.bg-neutral-900 {
  background-color: var(--clr-neutral-900);
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  font-size: var(--fs-primary-heading);
}
.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
}

.fs-300 {
  font-size: var(--fs-300);
}
.fs-400 {
  font-size: var(--fs-400);
}
.fs-500 {
  font-size: var(--fs-500);
}
.fs-600 {
  font-size: var(--fs-600);
}
.fs-700 {
  font-size: var(--fs-700);
}
.fs-800 {
  font-size: var(--fs-800);
}
.fs-900 {
  font-size: var(--fs-900);
}
.side-padding {
  padding-inline: var(--side-padding);
}

/* Utility Classes - BOTTOM */
