@import url("../App.css");

.feature-brief {
  background-color: #f7f7f9;
  padding-block: 3rem;
  max-width: 100vw;
  position: relative;
  contain: paint;
  gap: 2rem;
}
.feature-brief-title {
  text-align: center;
  padding-bottom: 1rem;
}
.feature-brief-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  row-gap: 2rem;
}
.feature-brief-caption {
  color: #333333;
  text-align: left;
  font-size: 20px;
  margin-top: 1rem;
  margin-bottom: 0;
}

.feature-brief-item {
  display: flex;
  justify-content: space-around;
  align-content: center;
  position: relative;
  align-items: center;
  gap: 1rem;
  opacity: 1;
}
@media (prefers-reduced-motion: no-preference) {
  .hidden {
    opacity: 0;
    gap: 20rem;
  }
  .show-feature-brief {
    animation: close-in 0.5s ease forwards;
  }

  @keyframes close-in {
    0% {
      gap: 20rem;
      opacity: 0;
    }
    70% {
      gap: 0.5rem;
    }
    100% {
      gap: 1rem;
      opacity: 1;
    }
  }
}
.feature-brief-step {
  height: fit-content;
  justify-self: center;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  width: max-content;
  z-index: 2;
  align-items: baseline;
  font-size: 3rem;
  background-color: whitesmoke;
}
.feature-brief-text {
  padding-left: 1.75rem;
}
.numbered-circle {
  background: var(--clr-primary-400);
  border-radius: 50%;
  height: auto;
  width: 3.5rem;
  min-width: max-content;
  line-height: 3.5rem;
  display: flex;
  justify-content: center;
}
.circle-number {
  width: min-content;
  height: min-content;
  min-width: max-content;
  color: white;
  font-size: 2.5rem;
}
.numbered-item {
  line-height: 3rem;
}
h2 {
  margin-bottom: 2rem;
  z-index: 1;
}
.feature-brief-waitlist-button {
  background-color: #2b2e32;
  border-width: 0;
  width: max-content;
  padding-block: 0.75rem;
  padding-inline: 1rem;
}
@media (max-width: 70em) {
  .feature-brief-emoji {
    display: block;
    padding-block: 1rem;
  }
  h2 {
    margin-bottom: 0;
  }
  .feature-brief {
    padding-block: 1rem;
  }
  .feature-brief-item {
    flex-direction: column;
  }
  .feature-brief-item > * {
    padding-block: 1rem;
  }
  .feature-brief-step {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-top: 1.5rem;
  }
  .feature-brief-item.reverse {
    flex-direction: column-reverse;
  }

  .feature-brief-text {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
  .numbered-circle {
    border-radius: 50%;
    height: auto;
    width: 3rem;
    line-height: 3rem;
    display: flex;
    justify-content: center;
  }
  .numbered-item {
    line-height: 2rem;
    text-align: center;
    padding-top: 1rem;
  }
  .feature-brief-caption {
    text-align: center !important;
    margin-bottom: 1rem;
    margin-top: 0rem;
  }
  .feature-brief-waitlist-button {
    align-self: center;
  }
}

.background-gradient {
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3.5);
  position: absolute;
  opacity: 0.15;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .background-gradient {
    animation: spin 3s linear infinite; /* change seconds value to make faster or shorter */
  }
  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg) scale(3.5);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) scale(3.5);
    }
  }
}

@media (max-width: 60em) {
  .background-gradient {
    transform: translate(-50%, -50%) scale(12);
    opacity: 0.2;
  }
  @media (prefers-reduced-motion: no-preference) {
    @keyframes spin {
      from {
        transform: translate(-50%, -50%) rotate(0deg) scale(12);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg) scale(12);
      }
    }
  }
}
