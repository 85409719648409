.headerContainery {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  top: 0;
  position: fixed;
  backdrop-filter: blur(8px);
  width: 100vw;
  z-index: 2;
  height: 5rem;
}
@media (max-width: 50em) {
  .headerContainery {
    padding: 1rem;
  }
}
.header-download-button {
  background-color: #2b2e32;
  border-width: 0;
  width: max-content;
  padding: 10px;
  padding-inline: 15px;
}
.header_icon {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  width: 200;
}

.custom_header_icon {
  object-fit: contain;
  max-width: 50%;
}

.logoutSpan {
  cursor: pointer;
  border: white 1px solid;
  padding: 5px;
  border-radius: 5px;
}
